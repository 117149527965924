html {
  scrollbar-gutter: stable;
  overflow-y: scroll;
}

// Weird little bug was scooting the footer down when .menu was open
body:has(dialog[open]:not(.menu)) {
  overflow: hidden;
}

.app-root {
  display: grid;
  grid-template-rows: auto 1fr auto;
  min-block-size: 100vh;
}

@media screen and (prefers-reduced-motion: no-preference) {
  html {
    scroll-behavior: smooth;
  }
}
