:root {
  // Global Spacing
  --xxxl-spacing: 5em; // 50px
  --xxl-spacing: 3.125em; // 50px
  --xl-spacing: 2.25rem; // 36px
  --l-spacing: 1.625rem; // 26px
  --m-spacing: 1.25rem; // 20px
  --s-spacing: 0.9375rem; // 15px
  --xs-spacing: 0.625rem; // 10px
  --xxs-spacing: 0.3125rem; // 5px

  // Header
  --header-height: calc(3.125em + 5px);

  // Global Theme Color
  --default-theme: #005bbb;
  --client-theme: var(--client-color, var(--default-theme));

  // Global Colors
  --white: #fff;
  --light-gray: #f5f5f5;
  --medium-gray: #949494;
  --black: #000;
  --dark-text: #4a4a4a;
  --global-error: #e91b11;
  --global-success: #82bc00;
  --global-secondary-color: #82bc00;
  --global-separator-light: #eee;
  --global-separator: #d9d9d9;
  --global-separator-dark: rgba(0 0 0 / 42%);
  --global-border-color: #ddd;
  --global-shadow-color: hsl(0deg 0% 0% / 15%);
  --icon-fill: var(--medium-gray);
  --global-link-color: #337ab7;
  --global-link-hover-color: #23527c;
  --global-payment-green: #4f8210;
  --global-label-color: #717170;

  // Insurance Navigation Colors
  --insurance-navigation-step-complete-fill: #8dc63f;
  --card-stripe-fill: rgb(204 204 204 / 30%);

  // Global Outlines (focus states)
  --outline-size: max(2px, 0.08em);
  --outline-style: solid;
  --outline-color: currentcolor;

  // Global radius
  --global-border-radius: 4px;

  // Headings
  --global-heading-small: #000;

  // Charge Lists
  --charge-list-item-color: #444442;
  --charge-denied: #bf0000;

  // Forms
  --global-form-heading: #000;
  --global-form-label: var(--global-label-color);
  --global-form-placeholder-color: #a5a5a2;
  --global-input-border: 1px solid var(--global-separator-dark);
  --global-input-error: var(--global-error);
  --global-input-readonly-background: #d2d2d0;
  --global-input-readonly-background-light: #e8e8e8;
  --global-button-background-color-disabled: #a5a5a2;

  // Buttons
  --global-button-background-color: var(--global-secondary-color);
  --global-button-background-hover-color: #5f8900;
  --global-button-background-color-secondary: #005bbb;
  --global-button-background-hover-color-secondary: #004288;
  --global-button-inline-color: #005bbb;

  // Radio
  --global-radio-outline-color: #6d6c6b;

  // Footer
  --footer-color: var(--white);
  --footer-hover-color: var(--light-gray);

  // Details Element
  --details-heading-background: #f5f5f5;
}
